<script setup>
import { refDebounced } from '@vueuse/core'
import { ref, watch } from 'vue'

defineProps({
  label: {
    type: String,
    default: 'Buscar',
  },
  inputLength: {
    type: Number,
    default: 2,
  },
})

const emit = defineEmits(['input'])

const buscar = ref(null)
const buscarDebounced = refDebounced(buscar, 500)

watch(buscarDebounced, (value, old) => {
  if (value != old) {
    emit('input', value)
  }
})
</script>

<template>
  <v-text-field
    v-model="buscar"
    :label="label"
    color="primary"
    density="compact"
    variant="outlined"
    prepend-inner-icon="mdi-magnify"
    hide-details
    clearable
  ></v-text-field>
</template>
