<script setup>
import { router } from '@inertiajs/vue3'
import { ref } from 'vue'

defineProps({
  mensaje: {
    type: String,
    required: false,
    default: '¿Seguro que desea eliminar el registro?',
  },
})

const emit = defineEmits(['update'])

const cargando = ref(false)
const modal = ref(false)
let ruta = null

function abrirModal(url) {
  ruta = url
  modal.value = true
}

function confirmar() {
  cargando.value = true
  router.delete(ruta, {
    preserveState: true,
    preserveScroll: true,
    onSuccess: () => {
      cargando.value = false
      modal.value = false
      emit('update')
    },
  })
}

defineExpose({
  abrirModal,
})
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="modal" persistent width="auto">
      <v-card>
        <v-card-text>{{ mensaje }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" variant="text" @click="modal = false" :disabled="cargando">Cancelar</v-btn>
          <v-btn color="error" variant="flat" :loading="cargando" @click="confirmar">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
