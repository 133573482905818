import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

const store = new Vuex.Store({
  state: {
    usuario: null,
    permisos: [],
  },
  mutations: {
    SET_USUARIO(state, usuario) {
      state.usuario = usuario
    },
    SET_PERMISOS(state, usuario) {
      state.permisos = usuario
    },
  },
  actions: {
    setUsuario({ commit }, usuario) {
      commit('SET_USUARIO', usuario)
    },
    setPermisos({ commit }, permisos) {
      commit('SET_PERMISOS', permisos)
    },
  },
  plugins: [vuexLocal.plugin]
})

export default store
