import './bootstrap'
import '../css/app.css'

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import vuetify from './Plugins/vuetify'
import toast from './Plugins/toast'
import { Head, Link } from '@inertiajs/vue3'
import InputBuscar from './Components/InputBuscar.vue'
import EliminarRegistro from './Components/EliminarRegistro.vue'
import CabeceraDetalle from './Components/CabeceraDetalle.vue'
import helpers from './Common/helpers'
import { ZiggyVue } from 'ziggy-js'
import { Ziggy } from '@/ziggy.js'
import store from './Store/auth'

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel'

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  setup({ el, App, props, plugin }) {
    const app = createApp({
      render: () => h(App, props),
    })
      .use(plugin)
      .use(store)
      .use(vuetify)
      .use(toast)
      .use(ZiggyVue, Ziggy)
      .component('InputBuscar', InputBuscar)
      .component('EliminarRegistro', EliminarRegistro)
      .component('CabeceraDetalle', CabeceraDetalle)
      .component('Head', Head)
      .component('Link', Link)
    app.config.globalProperties.$helpers = helpers;
    app.mount(el)
    return app
  },
  progress: {
    color: '#4CAF50',
  },
})
