import store from '@/Store/auth'
import moment from 'moment-timezone'
import 'moment/locale/es'

moment.tz.setDefault('America/La_Paz').locale('es')

export default {
  verificarPermisoMenu: function (menu) {
    if (menu.hasOwnProperty('permiso')) {
      if (menu.permiso.length > 0) {
        return store.state.permisos.some((p) => menu.permiso.includes(p))
      } else {
        return true
      }
    } else {
      return true
    }
  },
  verificarPermiso: function (permiso) {
    return store.state.permisos.includes(permiso)
  },
  formatoFecha(fecha, hora = false) {
    if (!fecha) {
      return '-'
    } else {
      let formato = hora ? 'DD/MM/YY HH:mm' : 'DD/MM/YY'
      return moment(fecha).format(formato)
    }
  }
}
